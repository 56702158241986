import { render, staticRenderFns } from "./ResourceDetail.vue?vue&type=template&id=abf8ea8c&scoped=true&"
import script from "./ResourceDetail.vue?vue&type=script&lang=js&"
export * from "./ResourceDetail.vue?vue&type=script&lang=js&"
import style0 from "./ResourceDetail.vue?vue&type=style&index=0&id=abf8ea8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abf8ea8c",
  null
  
)

export default component.exports